import { FormCheckbox, FormSelect, TextInput } from '../../../form-units';
import { types, utils } from '../../duck';
import { TeamIndividualEnum } from '../../duck/types';
import { Divider, Flex, FormControl, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { WorkingLocation } from '@lon/shared/contexts';
import { DistrictPermission } from '@lon/shared/enums';
import { useGetIsLeaderSuitFlag, usePermission } from '@lon/shared/hooks';
import {
  ScopePlanTypeEnum,
  useGetGradesFromSchoolIdQuery,
  useGetPlannerCurriculumAreasQuery,
} from '@lon/shared/requests';
import * as plannerSelectors from '@lon/shared/selectors';
import { IconVariantType } from '@lon/shared/types';
import { PlansListArrayType } from '@lon/shared/types';
import { sortAlphabetical, sortGrades } from '@lon/shared/utils';
import styles from './TeamIndividualPlan.module.css';

const TeamIndividualPlan: React.FC<types.TeamIndividualPlanType> = ({
  formProps,
  pacing,
  viewOnly,
  selectedTab,
  team,
  partialViewOnly,
  addElementProps,
  isEdit,
  pacingLoading,
  suggestedPlansTemplates,
}) => {
  const { t } = useTranslation();
  const { currentSchoolId, currentAppSchoolIds } = useContext(WorkingLocation);
  const isLeaderSuit = useGetIsLeaderSuitFlag();
  const { setValue, watch, resetField, getValues } = formProps;
  const [canAccessAIPlanner] = usePermission([
    {
      permission: DistrictPermission.TEACHER_SUIT,
      module: 'lessonPlanner',
      operation: 'access',
    },
  ]);

  const { data: curriculumAreasData, loading: curriculumAreasLoading } =
    useGetPlannerCurriculumAreasQuery({
      ...(!isLeaderSuit
        ? {
            skip: !currentSchoolId,
            variables: { schoolId: currentSchoolId as string },
          }
          : {
            skip: !currentAppSchoolIds,
            variables: { schoolIds: currentAppSchoolIds },
          }),
    });
  const { teamsList, loading: loadingTeamsList } = useSelector(
    plannerSelectors.teams
  );

  const { data: gradesData, loading: gradesLoading } =
    useGetGradesFromSchoolIdQuery({
      ...(!isLeaderSuit
        ? { skip: !currentSchoolId, variables: { schoolId: currentSchoolId } }
        : {
          skip: !currentAppSchoolIds,
          variables: { schoolIds: currentAppSchoolIds },
        }),
    });
  const plansList = useSelector(plannerSelectors.plansList);

  const [allowedPacing, setAllowedPacing] = useState<types.displayLabel[]>();
  const [allowedGrades, setAllowedGrades] = useState<types.displayLabel[]>();
  const [allowedCurr, setAllowedCurr] = useState<types.displayLabel[]>();
  const [disableGradeCurriculumSelection, setDisableGradeCurriculumSelection] =
    useState(false);

  const selectedSuggestedPlanTemplate = watch('scopePlanTemplateId');
  const selectedPlanTemplate = suggestedPlansTemplates?.collection?.find(
    (plan) => plan?._id === selectedSuggestedPlanTemplate?.value
  );

  const selectedCurriculumArea = watch('curriculumArea');
  const selectedGrade = watch('grade');
  const selectedTeamId = watch('teamId');
  const selectedPacing = watch(
    team === TeamIndividualEnum.Individual
      ? 'pacingGuideInd'
      : 'pacingGuideTeam'
  );
  const isAdaptivePlan = watch('adaptive');

  useEffect(() => {
    if (addElementProps) {
      setValue('grade', {
        label: addElementProps.grade,
        value: addElementProps.grade,
      });
      setValue('curriculumArea', {
        label: addElementProps.curriculumArea,
        value: addElementProps.curriculumArea,
      });
    }
  }, [addElementProps]);

  useEffect(() => {
    if (selectedSuggestedPlanTemplate?.value && !isEdit) {
      setValue(
        'curriculumArea',
        {
          label: selectedPlanTemplate?.curriculumArea,
          value: selectedPlanTemplate?.curriculumArea,
        },
        {
          shouldDirty: true,
        }
      );

      setValue(
        'grade',
        {
          label: selectedPlanTemplate?.grade,
          value: selectedPlanTemplate?.grade,
        },
        {
          shouldDirty: true,
        }
      );

      setDisableGradeCurriculumSelection(true);
    } else {
      setDisableGradeCurriculumSelection(false);
    }
  }, [selectedSuggestedPlanTemplate]);

  // Updates allowedPacing whenever allowedCurr/allowedGrades change
  useEffect(() => {
    if (selectedGrade && selectedCurriculumArea) {
      setAllowedPacing(
        utils.getAllowedPacing(
          selectedGrade,
          selectedCurriculumArea,
          pacing,
          team === TeamIndividualEnum.Individual ? 'Individual' : 'Team',
          selectedTeamId,
          isEdit
        )
      );
    }
  }, [allowedCurr, allowedGrades, selectedTeamId, pacing]);

  // Resets the form if the selectedPacing is no longer allowed
  useEffect(() => {
    if (
      allowedPacing?.findIndex(
        (pace) => pace?.value === selectedPacing?.value
      ) === -1 &&
      !isEdit
    ) {
      setValue(
        team === TeamIndividualEnum.Individual
          ? 'pacingGuideInd'
          : 'pacingGuideTeam',
        undefined
      );
    }
  }, [allowedPacing]);

  /* This logic determines which fields are greyed out based off the existing plans of type = Individual, and
  the selected curriculum / grade, to output the results as allowedCurr / allowedGrade states. Then, an effect that
  is dependent on allowedCurr/ allowedGrades will update the allowedPacing. */
  useEffect(() => {
    const curriculumArray = sortAlphabetical(
      curriculumAreasData?.curriculumAreas?.curriculumAreas
    );
    const gradesArray = sortGrades(gradesData?.grades?.grades);
    const plansArray: PlansListArrayType = plansList?.list;
    if (
      curriculumArray?.length &&
      plansArray?.length &&
      gradesArray?.length &&
      selectedGrade &&
      selectedCurriculumArea
    ) {
      const gradesOptions = utils.getGradesToDisplay(
        gradesArray!,
        plansArray,
        selectedCurriculumArea?.value,
        team === TeamIndividualEnum.Individual ? 'Individual' : 'Team'
      );
      const currOptions = utils.getCurrToDisplay(
        curriculumArray!,
        plansArray,
        selectedGrade?.value,
        team === TeamIndividualEnum.Individual ? 'Individual' : 'Team'
      );
      setAllowedGrades(gradesOptions);
      setAllowedCurr(currOptions);
    } else if (
      selectedGrade &&
      !selectedCurriculumArea &&
      curriculumArray?.length &&
      plansArray?.length
    ) {
      resetField('curriculumArea');
      setAllowedCurr(
        utils.getCurrToDisplay(
          curriculumArray!,
          plansArray,
          selectedGrade?.value,
          team === TeamIndividualEnum.Individual ? 'Individual' : 'Team'
        )
      );
    } else if (
      selectedCurriculumArea &&
      !selectedGrade &&
      gradesArray?.length &&
      plansArray?.length
    ) {
      resetField('grade');
      setAllowedGrades(
        utils.getGradesToDisplay(
          gradesArray!,
          plansArray,
          selectedCurriculumArea?.value,
          team === TeamIndividualEnum.Individual ? 'Individual' : 'Team'
        )
      );
    } else {
      setAllowedCurr(utils.mapStringToDisplay(curriculumArray));
      setAllowedGrades(utils.mapStringToDisplay(gradesArray));
    }
  }, [selectedGrade, selectedCurriculumArea, curriculumAreasData, gradesData]);

  useEffect(() => {
    if (!viewOnly && !isEdit) {
      setAllowedPacing([]);
      resetField('grade');
      resetField('curriculumArea');
      resetField('teamId');
      setValue(
        team === TeamIndividualEnum.Individual
          ? 'pacingGuideInd'
          : 'pacingGuideTeam',
        { value: '' }
      );
      resetField('adaptive');
      resetField('classSize');
      resetField('classTime');
      resetField('experience');
      resetField('scopePlanTemplateId');
      setDisableGradeCurriculumSelection(false);
    }
  }, [selectedTab]);

  return (
    <Flex className={styles.wrapper}>
      <Flex className={styles.topGroup}>
        {suggestedPlansTemplates?.collection?.length && !isEdit ? (
          <FormControl px={{ base: 4, md: 8 }}>
            <FormSelect
              testId="suggestedPlanTemplate"
              name="scopePlanTemplateId"
              label={t('planTemplate.suggestedPlanTemplateLable')}
              placeholder={t('planTemplate.suggestedPlanTemplatePlaceHolder')}
              isClearable
              showClearIndicator
              isDisabled={isEdit}
              options={
                suggestedPlansTemplates?.collection?.map((plan) => ({
                  label: plan?.name || '',
                  value: plan?._id || '',
                  isDisabled: Boolean(
                    plansList?.list?.find(
                      (pl) =>
                        pl?.grade === plan?.grade &&
                        pl?.curriculumArea === plan?.curriculumArea &&
                        pl?.scopePlanType ===
                          (team
                            ? ScopePlanTypeEnum.Team
                            : ScopePlanTypeEnum.Individual)
                    )
                  ),
                })) || []
              }
            />
          </FormControl>
        ) : (
          <></>
        )}
        {team === TeamIndividualEnum.Team && (
          <FormControl isRequired px={{ base: 4, md: 8 }}>
            <FormSelect
              testId="teamId"
              isRequired
              isLoading={loadingTeamsList}
              name="teamId"
              placeholder={t('pacingGuide.teams')}
              label={t('pacingGuide.teams')}
              formLabelProps={{
                width: 'full',
              }}
              options={teamsList}
              disabled={isEdit}
            />
          </FormControl>
        )}
        <FormControl px={{ base: 4, md: 8 }}>
          <FormSelect
            testId={
              team === TeamIndividualEnum.Individual
                ? 'curriculumArea-ind'
                : 'curriculumArea-team'
            }
            isRequired
            isLoading={curriculumAreasLoading}
            name="curriculumArea"
            label={t('plans.curriculumArea')}
            placeholder={t('plans.curriculumAreaPlaceholder')}
            disabled={
              isEdit || partialViewOnly || disableGradeCurriculumSelection
            }
            className={styles.greyLabel}
            options={allowedCurr || []}
          />
        </FormControl>

        <FormControl px={{ base: 4, md: 8 }}>
          <FormSelect
            testId={
              team === TeamIndividualEnum.Individual
                ? 'grade-ind'
                : 'grade-team'
            }
            isRequired
            isLoading={gradesLoading}
            name="grade"
            label={t('plans.grade')}
            placeholder={t('plans.gradePlaceholder')}
            className={styles.greyLabel}
            iconVariant={IconVariantType.Hidden}
            disabled={
              isEdit || partialViewOnly || disableGradeCurriculumSelection
            }
            options={allowedGrades || []}
          />
        </FormControl>
        {allowedPacing?.length ||
        getValues()[
          team === TeamIndividualEnum.Individual
            ? 'pacingGuideInd'
            : 'pacingGuideTeam'
        ]?.value ? (
          <>
            <Divider orientation="horizontal" mt={0} mb={0} />
            <FormControl px={8}>
              <FormSelect
                name={
                  team === TeamIndividualEnum.Individual
                    ? 'pacingGuideInd'
                    : 'pacingGuideTeam'
                }
                label={t('plans.pacingGuideLabel')}
                placeholder={t('plans.selectPacingGuideHolder')}
                className={styles.greyLabel}
                isClearable
                showClearIndicator
                iconVariant={IconVariantType.Hidden}
                isLoading={pacingLoading}
                options={allowedPacing || []}
              />
            </FormControl>
          </>
        ) : null}
        {/*Adaptive Button*/}
        {(isEdit || canAccessAIPlanner) && (
          <Flex px={{ base: 4, md: 8 }} color="primary.800">
            <FormCheckbox
              name="adaptive"
              label={
                <Text variant="n3" color="primary.800" wordBreak="break-word">
                  {t('plans.adaptive')}
                </Text>
              }
              isDisabled={viewOnly || isEdit || !canAccessAIPlanner}
              gap={0}
              size="md"
            />
          </Flex>
        )}

        {isAdaptivePlan && (
          <Flex
            px={{ base: 4, md: 8 }}
            color="primary.800"
            flexDirection="column"
            fontWeight={700}
            gap="1rem"
            flexGrow="3"
            fontSize="1rem"
            lineHeight="1.375rem"
          >
            <Flex flexDirection="column">
              <TextInput
                type="number"
                name="classTime"
                isRequired
                label={t('plans.adaptiveTime')}
                placeholder={t('plans.adaptiveTimePlaceholder')}
                onWheel={(event) => event.currentTarget.blur()}
                isDisabled={viewOnly || !canAccessAIPlanner}
              />
            </Flex>
            <Flex flexDirection="column">
              <TextInput
                name="experience"
                type="number"
                isRequired
                label={t('plans.adaptiveExperience')}
                placeholder={t('plans.adaptiveExperiencePlaceholder')}
                onWheel={(event) => event.currentTarget.blur()}
                isDisabled={viewOnly || !canAccessAIPlanner}
              />
            </Flex>
            <Flex flexDirection="column">
              <TextInput
                name="classSize"
                type="number"
                isRequired
                label={t('plans.adaptiveClassSize')}
                placeholder={t('plans.adaptiveClassSizePlaceholder')}
                onWheel={(event) => event.currentTarget.blur()}
                isDisabled={viewOnly || !canAccessAIPlanner}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default TeamIndividualPlan;
