import { SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BackButton, LinkCard } from '@lon/shared/components';
import { routes } from '@lon/shared/configs';
import { DistrictPermission } from '@lon/shared/enums';
import { useAcl, useMediaQuery } from '@lon/shared/hooks';
import { StudentPageContent } from '@lon/suit/components';

const Playground: React.FC = () => {
  const hasCoding = useAcl({
    permission: DistrictPermission.STUDENT_SUIT,
    module: 'coding',
    operation: 'view',
  });
  const { t } = useTranslation();
  const [isBigMobile] = useMediaQuery(
    '(min-width: 376px) and (max-width: 767px)'
  );
  const [isLargerThan1680] = useMediaQuery('(min-width: 1680px)');

  return (
    <>
      <Helmet>
        <title>{t('additionalResources.title')}</title>
      </Helmet>
      <StudentPageContent
        hasTabletPaddings
        pageTitle={t('additionalResources.heading')}
        headerElements={
          <BackButton
            label={t('backButton.default.label')}
            tooltipLabel={t('backButton.default.tooltipLabel')}
            to={routes.home}
          />
        }
        rightColumnComponent={
          <SimpleGrid
            spacing={6}
            columns={
              isBigMobile ? 2 : isLargerThan1680 ? 5 : { base: 1, md: 2, lg: 4 }
            }
            as="ul"
          >
            {hasCoding && (
              <LinkCard
                to={routes.coding.root}
                hint={t('codingTool.openCodingTool')}
                linkContent={
                  <Text variant="h6" py={2} px={4} color="primary.800">
                    {t('codingTool.title')}
                  </Text>
                }
                imageUrl={'suit/assets/coding_tool.png'}
                as="li"
              />
            )}
          </SimpleGrid>
        }
      />
    </>
  );
};

export default Playground;
