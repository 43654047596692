import { Button, Text } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Icon, NoContent } from '@lon/shared/components';
import { DistrictPermission } from '@lon/shared/enums';
import { useMediaQuery, usePermission } from '@lon/shared/hooks';
import { routes } from '@lon/shared/configs';
import { ClassContext } from '@lon/suit/contexts';

const NoClasses: FC = () => {
  const { t } = useTranslation();
  const { isMainTeacher } = useContext(ClassContext);
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [canCreateClass] = usePermission([
    {
      permission: DistrictPermission.TEACHER_SUIT,
      module: 'classes',
      operation: 'create',
    },
  ]);

  return (
    <NoContent
      title={<Trans i18nKey={t('classes.noClasses')} />}
      description={
        <Trans
          i18nKey={
            isMainTeacher
              ? t('classes.addClasses')
              : t('classes.noClassesAdditionalView')
          }
        />
      }
      actions={
        isMainTeacher && canCreateClass ? (
          <Button
            as={NavLink}
            to={routes.classes.create}
            variant="solid"
            mb="24"
            leftIcon={<Icon name="plus" />}
            onClick={() => {}}
            {...(!isMobile && { mt: 10 })}
          >
            <Text as="span" isTruncated>
              {t('classes.createClassBtn')}
            </Text>
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
};

export default NoClasses;
