import { collapsibleSidebarPaddingRight } from '../../../../../collapsible-sidebar';
import { Flex, Tag, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetSchoolIdsForPlanner as useGetSchoolIds,
  useGetTheme,
} from '@lon/shared/hooks';
import {
  CurriculumAreaEnum,
  useGetHowToScopesQuery,
} from '@lon/shared/requests';
import { getThemeStyleValue } from '@lon/shared/utils';
import { HowToScope } from './duck';
import { BannerSkeleton, ScopeButton } from './components';

const Banner: React.FC = () => {
  const { t } = useTranslation();
  const currentTheme = useGetTheme();
  const accessibleSchoolIds = useGetSchoolIds();

  const { data, loading } = useGetHowToScopesQuery({
    variables: {
      schoolIds: accessibleSchoolIds || '',
    },
    skip: !accessibleSchoolIds?.length,
  });

  const { scienceScopes, mathScopes } = useMemo(
    () =>
      data?.howToScopes?.collection.reduce(
        (acc, scope) => {
          if (scope?.metadata?.curriculumArea === CurriculumAreaEnum.Science) {
            return {
              ...acc,
              scienceScopes: [...acc.scienceScopes, scope],
            };
          } else if (
            scope?.metadata?.curriculumArea === CurriculumAreaEnum.Math
          ) {
            return {
              ...acc,
              mathScopes: [...acc.mathScopes, scope],
            };
          } else {
            return acc;
          }
        },
        {
          scienceScopes: [],
          mathScopes: [],
        } as {
          scienceScopes: HowToScope[];
          mathScopes: HowToScope[];
        }
      ) || {
        scienceScopes: [],
        mathScopes: [],
      },
    [data?.howToScopes?.collection]
  );

  if (!loading && !scienceScopes.length && !mathScopes.length) {
    return <></>;
  }

  return (
    <Flex
      background="linear-gradient(292.22deg, #1E365F 17.66%, #076168 85.85%), linear-gradient(57.57deg, rgba(68, 181, 117, 0) 47.36%, rgba(68, 181, 117, 0.5) 100%)"
      border={getThemeStyleValue('none', '1px solid #FFFFFF')(currentTheme)}
      flex={1}
      color="white"
      boxShadow="pane"
      p={6}
      pr={collapsibleSidebarPaddingRight}
      gap={4}
      w="100%"
      flexDir="column"
    >
      {loading ? (
        <BannerSkeleton />
      ) : (
        <>
          <Text variant="h6">{t('howToScope.bannerTitle')}</Text>
          <Text variant="s3" fontStyle="italic" whiteSpace="pre-wrap">
            {t('howToScope.bannerDescription')}
          </Text>
          {scienceScopes.length ? (
            <Flex direction="column" gap="4">
              <Tag variant="successOutline" borderRadius="xl" w="fit-content">
                <Text as="span" variant="tag" color="green.900">
                  {t('howToScope.science')}
                </Text>
              </Tag>
              {scienceScopes?.map((scope) => (
                <ScopeButton key={scope.id} scope={scope} />
              ))}
            </Flex>
          ) : null}
          {mathScopes.length ? (
            <Flex direction="column" gap="4">
              <Tag variant="successOutline" borderRadius="xl" w="fit-content">
                <Text as="span" variant="tag" color="green.900">
                  {t('howToScope.math')}
                </Text>
              </Tag>
              {mathScopes?.map((scope) => (
                <ScopeButton key={scope.id} scope={scope} />
              ))}
            </Flex>
          ) : null}
        </>
      )}
    </Flex>
  );
};

export default Banner;
