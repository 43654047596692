import { Icon } from '../icon';
import { Box, Button } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IMPERSONATE_BORDER_BOTTOM_WIDTH_REM,
  IMPERSONATE_BORDER_WIDTH_REM,
} from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { ProfileTypeEnum } from '@lon/shared/enums';
import { useMediaQuery } from '@lon/shared/hooks';
import { useCancelImpersonationMutation } from '@lon/shared/requests';
import { ImpersonatedUserLS } from '@lon/shared/types';
import { constants, utils } from './duck';
import { Loader } from './components';

const btnNameByType = {
  [ProfileTypeEnum.STUDENT]: 'impersonate.leaveStudentSuitBtn',
  [ProfileTypeEnum.TEACHER]: 'impersonate.leaveTeacherSuitBtn',
  [ProfileTypeEnum.PARENT]: 'impersonate.leaveParentSuitBtn',
  [ProfileTypeEnum.ADMIN]: 'impersonate.leaveAdminBtn',
  [ProfileTypeEnum.CAMPUS_LEADER]: 'impersonate.leaveLeaderViewBtn',
  [ProfileTypeEnum.CAMPUS_CURRICULUM_LEADER]: 'impersonate.leaveLeaderViewBtn',
  [ProfileTypeEnum.DISTRICT_CURRICULUM_LEADER]:
    'impersonate.leaveLeaderViewBtn',
};

const ImpersonateWrapper: React.FC<any> = ({ children }) => {
  const { t } = useTranslation();
  const { userType: mainUserType } = useContext(WorkingLocation);
  //@ts-ignore
  const [auth] = constants.authLibrary[mainUserType || 'staff']();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [cancelImpersonation, { loading: impersonationLoading }] =
    useCancelImpersonationMutation();
  const currentProfileType = auth?.profileType as Exclude<
    ProfileTypeEnum,
    ProfileTypeEnum.PLATFORM_ADMIN
  >;
  const currentMainProfileType = (
    JSON.parse(
      localStorage.getItem('impersonate') || '{}'
    ) as ImpersonatedUserLS
  )?.mainProfileType;

  return (
    <>
      <Box
        height="100vh"
        border={`${IMPERSONATE_BORDER_WIDTH_REM}rem solid #583F79`}
        borderBottom={`${IMPERSONATE_BORDER_BOTTOM_WIDTH_REM}rem solid #583F79`}
        overflow="hidden"
      >
        {children}
        <Button
          variant="solidDark"
          size="sm"
          position="fixed"
          bottom="0.625rem"
          right={{ base: '20px', md: '60px' }}
          rightIcon={<Icon name="arrow-right-outlined" />}
          onClick={() => {
            utils.cancelImpersonation({
              cancelImpersonationMutation: cancelImpersonation,
            });
          }}
        >
          {isMobile
            ? t('impersonate.leaveBtn')
            : t(btnNameByType[currentProfileType]) || t('impersonate.leaveBtn')}
        </Button>
      </Box>
      <Loader
        isLoading={impersonationLoading}
        mainProfileType={currentMainProfileType}
      />
    </>
  );
};

export default ImpersonateWrapper;
