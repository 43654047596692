import { routes } from '@lon/shared/configs';
import { SidebarLink } from './types';

export const firstSection: SidebarLink[] = [
  {
    id: '1',
    title: 'Classes',
    path: routes.classes.root,
    icon: 'user-group',
    end: true,
    pathsToHighlight: [
      '/classes',
      '/classes/create',
      '/classes/settings',
      {
        path: '/classes/:classId',
        regExp: /^\/classes\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/?$/,
      },
      '/classes/:classId/settings',
      '/classes/:classId/gradebook',
      '/classes/:classId/roster',
      '/classes/:classId/roster/students-page',
      '/classes/:classId/roster/manage-subgroups',
      '/classes/:classId/roster/manage-subgroups/:subgroupId',
      '/classes/:classId/roster/manage-subgroups/:subgroupId',
    ],
  },
  {
    id: '2',
    title: 'Assignments',
    path: routes.classes.assignments.root,
    icon: 'subscription-outlined',
    pathsToHighlight: [
      '/classes/:classId/assignment/:assignmentId',
      '/classes/:classId/student-assignments/:studentId',
      '/classes/district-assessments/:studentId/:sessionId',
      '/classes/student-assignments/:studentId',
      '/classes/assignments',
      '/classes/assignments/:assignmentId',
      '/classes/assignments/assignment-list/:listId',
    ],
  },
  {
    id: '3',
    title: 'Students',
    path: routes.classes.students,
    icon: 'school-outlined',

    pathsToHighlight: [
      '/classes/students',
      '/classes/create-student',
      '/classes/edit-student/:studentId',
    ],
  },
];

export const getSecondSection: (
  canCreateClasses: boolean,
  canCreateStudent: boolean
) => SidebarLink[] = (canCreateClasses, canCreateStudent) => {
  const links = [];

  if (canCreateClasses) {
    links.push({
      id: '4',
      title: 'Create Class',
      path: routes.classes.create,
    });
  }

  if (canCreateStudent) {
    links.push({
      id: '5',
      title: 'Create Student',
      path: routes.classes.createStudent,
    });
  }

  return links;
};

export const reportSection: {
  title: string;
  path?: string;
  icon: string;
  subItems: { title: string; path: string }[];
}[] = [
  {
    title: 'Reports',
    icon: 'report',
    subItems: [
      {
        title: 'Standards Report',
        path: routes.classes.reportDashboard.standard,
      },
      {
        title: 'District Assessments Reports',
        path: routes.classes.reportDashboard.districtAssessments,
      },
    ],
  },
];
