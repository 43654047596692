import { App } from '@suit/app';
import { SharedPages, StudentPages } from '@suit/pages';
import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { NotFound, ProtectedRoute } from '@lon/shared/components';
import { ApplicationEnum, DistrictPermission } from '@lon/shared/enums';
import { ErrorBoundary, TestError } from '@lon/shared/pages';
import { routes } from '@lon/shared/configs';

const StudentRouter = createRoutesFromElements(
  <Route
    path={routes.home}
    element={<App />}
    errorElement={<ErrorBoundary application={ApplicationEnum.STUDENT_SUIT} />}
  >
    <Route index element={<StudentPages.Dashboard />} />
    <Route path={routes.student.help.root}>
      <Route index element={<SharedPages.HelpPage />} />
      <Route
        path={routes.student.help.details}
        element={<SharedPages.HelpPageDetails />}
      />
    </Route>

    <Route path={routes.notebook.root}>
      <Route index element={<StudentPages.Notebook />} />
      <Route
        path={routes.notebook.scopes}
        element={<StudentPages.StudentScopes />}
      />
      <Route
        path={routes.notebook.assignments}
        element={<StudentPages.Assignments />}
      />
      <Route
        path={routes.notebook.otherAssignments}
        element={<StudentPages.Assignments isOtherAssignments />}
      />
      <Route
        path={routes.notebook.assignment}
        element={<StudentPages.StudentAssignment />}
      />
      <Route
        path={routes.notebook.otherAssignment}
        element={<StudentPages.StudentAssignment isOtherAssignment />}
      />
      <Route
        path={routes.notebook.districtAssignment}
        element={<StudentPages.DistrictAssignment />}
      />
      <Route
        path={routes.notebook.resource}
        element={<StudentPages.StudentResource />}
      />
    </Route>
    <Route path={routes.resources.root}>
      <Route index element={<SharedPages.Resources />} />
      <Route path={routes.resources.show} element={<SharedPages.Resource />} />
    </Route>
    <Route
      path={routes.coding.root}
      element={
        <ProtectedRoute
          permission={DistrictPermission.STUDENT_SUIT}
          module="coding"
          operation="view"
        >
          <SharedPages.Coding />
        </ProtectedRoute>
      }
    />
    <Route path={routes.grades.root}>
      <Route index element={<SharedPages.Grades />} />
      <Route path={routes.grades.class} element={<SharedPages.Grades />} />
    </Route>
    <Route
      path={routes.playground.root}
      element={<StudentPages.Playground />}
    />
    <Route path={routes.testError} element={<TestError />} />
    <Route
      path={routes.googleClassRoom.root}
      element={<StudentPages.GoogleClassRoom />}
    />
    <Route path="*" element={<NotFound withoutSidebar />} />
  </Route>
);

export default StudentRouter;
